
import Vue from 'vue'

let w = (window as any);
let v = null;
let booking = null;
w.culqi = async function () {
    //store.commit('setCulqiToken', token) /* CREA ESTE COMMIT EN TU STORE QUE GUARDE EL TOKEN EN EL STATE */    
    if (w.Culqi.token) { // �Objeto Token creado exitosamente!
        w.Culqi.close();
        
        booking.culqi.token = w.Culqi.token.id;
        booking.culqi.active = w.Culqi.token.active;
        booking.culqi.country_code = w.Culqi.token.client.ip_country_code;
        booking.culqi.card_number = w.Culqi.token.card_number;
        booking.culqi.email = w.Culqi.token.email;
        booking.culqi.card_brand = w.Culqi.token.iin.card_brand;

        let newBooking = await v.$store.dispatch({
            type: 'booking/create',
            data: booking
        });

        if (newBooking.id != undefined) {
            v.$toast('Hemos recibido pago y se confirmo tu Reserva!', {
                color: 'success',
                icon: 'check_circle_outline',
                closeColor: 'white',
                x: 'center',
                y: 'bottom',
                timeout: 8000,
                showClose: true
            });

            v.$store.commit('booking/reset');
            v.$store.commit('booking/view', newBooking);
            v.$router.push({ name: 'bookproduct', params: { id: newBooking.id } });
        } 
        
        //alert('Se ha creado un token:' + token);
        //En esta linea de codigo debemos enviar el "Culqi.token.id"
        //hacia tu servidor con Ajax
    } else { // �Hubo alg�n problema!
        // Mostramos JSON de objeto error en consola
        console.log(w.Culqi.error);
        //v.$toast.error(w.Culqi.error);
        //alert(w.Culqi.error.user_message);
    }
}

export default class CulqiClass {
    constructor(vm: Vue, public_key) {
        w.Culqi.publicKey = public_key;
        v = vm;
    }
    open(model) {
        booking = model;
        booking.culqi = {
            description: 'Luego de aceptado tu pago de registrda tu reserva.',
            amount: booking.total_wt * 100,
            currency_code: 'PEN',
            installments: 1,
        };

        w.Culqi.options({
            customButton: 'Confirmar',
            paymentMethods: {
                tarjeta: true,
                yape: true
            },
            style: {
                logo: location.origin + '/img/icons/apple-icon-57x57.png',
                maincolor: '#336380',
                buttontext: '#ffffff',
                maintext: '#4A4A4A',
                desctext: '#4A4A4A'
            }
        });

        w.Culqi.settings({
            title: 'Padel Nation',
            currency: booking.culqi.currency_code,
            //description: 'Se te realizara un Cobro de S/. 1.00 para verificar esta tarjeta.',
            description: booking.culqi.description,
            amount: booking.culqi.amount
        });

        w.Culqi.open();
    }
}
